import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Layout } from "../components/common";
import cpd from "../images/about/cpd.svg";

const About = () => {
    const data = useStaticQuery(graphql`
        query Images {

            allGhostSettings {
                edges {
                    node {
                        facebook
                    }
                }
            }

            lauren: file(relativePath: { eq: "about/laurenVan.jpeg" }) {
                ...fluidImage
            }
            fafd: file(relativePath: { eq: "about/fafd.jpg" }) {
                ...fluidImage
            }
            petplan: file(relativePath: { eq: "about/sanctuary-logo.png" }) {
                ...fluidImage
            }
        }
    `);

    const site = data.allGhostSettings.edges[0].node;

    const facebookUrl = site.facebook
        ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}`
        : null;

    return <>
        <Helmet title="About" />
        <Layout>
            <div className="container">
                <h1 className="title">About</h1>
                <h2 style={{ textAlign: `center` }}>
                    Hello and welcome to our page!
                </h2>
                <GatsbyImage
                    image={data.lauren.childImageSharp.gatsbyImageData}
                    className="profileImage"
                    alt="Lauren" />
                <p>
                    Thank you for your interest in Crawley Dog Walkers. Let me tell you a bit about us and what makes us different from the other walkers out there.

                </p>
                <p>
                    To start off with we genuinely love and care for all the dogs that we take out. Your pet is not just a pay check to us. We spend a lot of time assessing new dogs and making sure
                    that our doggies all get along nicely because of this we really create a strong bond with each dog we take on.
                </p>
                <p>
                    Myself (Lauren) and Jasmine (Jaz) are best friends who both have backgrounds in animal care and have adored dogs from a young age. Together we have experience in vet nursing, canine psychology, animal first aid, dog ownership, dog walking and dog behaviours.
                </p>
                <p>
                    I am a proud paw-rent and have a Border Collie named Freya. She is my absolute world and coincidentally she is best friends with Jaz's fur baby Sky, a beautiful Spanish rescue.
                </p>
                <p>
                    We truly adore working with dogs and we feel its the best job in the world!
                </p>
                <p>
                    For pictures and updates of our services please visit our <a href={facebookUrl} target="_blank">facebook page</a>.
                </p>
                <p>
                    Thanks again for visiting, speak soon!
                </p>
                <p>
                    Lauren x
                </p>
                <div className="badges">
                    <a
                        href="https://newskillsacademy.co.uk/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={cpd} alt="Something" />
                    </a>
                    <a
                        href="https://www.petplansanctuary.co.uk/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <GatsbyImage
                            image={data.petplan.childImageSharp.gatsbyImageData}
                            alt="Insured with petplan" />
                    </a>
                    <a
                        href="https://firstaidfordogs.co.uk/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <GatsbyImage
                            image={data.fafd.childImageSharp.gatsbyImageData}
                            alt="First aid for dogs logo" />
                    </a>
                </div>
            </div>
        </Layout>
    </>;
};

export default About;

export const fluidImage = graphql`fragment fluidImage on File {
  childImageSharp {
    gatsbyImageData(width: 500, layout: CONSTRAINED)
  }
}
`;
